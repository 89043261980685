import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	TableHead,
	Paper,
	Box,
	IconButton,
	CircularProgress,
	Typography,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";

const TablePaginationActions = (props) => {
	const { count, page, rowsPerPage, onPageChange } = props;
	const theme = useTheme();

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
};

const DataTable = ({ columns, data, totalItems, page, pageSize, onPageChange, onPageSizeChange, actions, loading }) => {
	const theme = useTheme();
    const totalPages = Math.max(0, Math.ceil(totalItems / pageSize) - 1);

	return (
		<Box>
			<TableContainer component={Paper} style={{ border: `1px solid ${theme.palette.divider}` }}>
				<Table style={{ overflowX: "auto" }} stickyHeader size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.field}
									style={{ whiteSpace: "nowrap", zIndex: 1, ...column.style }}>
									{column.headerName}
								</TableCell>
							))}
							{actions && <TableCell style={{ whiteSpace: "nowrap", zIndex: 1 }}>Actions</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							<TableRow>
								<TableCell colSpan={columns.length + 1} align="center">
									<CircularProgress />
								</TableCell>
							</TableRow>
						) : data.length === 0 ? (
							<TableRow>
								<TableCell colSpan={columns.length + 1} align="center">
									<Typography>No records found</Typography>
								</TableCell>
							</TableRow>
						) : (
							data.map((row) => (
								<TableRow key={row.id} style={{ whiteSpace: "nowrap" }}>
									{columns.map((column) => (
										<TableCell key={column.field}>
											{column.customRenderCell ? column.customRenderCell(row) : row[column.field]}
										</TableCell>
									))}
									{actions && (
										<TableCell
											style={{
												position: "sticky",
												right: 0,
												backgroundColor: theme.palette.background.paper,
												zIndex: 1,
											}}>
											{actions.map((action, index) => (
												<React.Fragment key={index}>{action.icon(row)}</React.Fragment>
											))}
										</TableCell>
									)}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				style={{
					display: "flex",
					justifyContent: "flex-end",
					backgroundColor: theme.palette.background.paper,
					padding: "10px",
					borderLeft: `1px solid ${theme.palette.divider}`,
					borderRight: `1px solid ${theme.palette.divider}`,
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					count={totalItems}
					rowsPerPage={pageSize}
					page={Math.min(page, totalPages)} // Ensure page is within range
					onPageChange={onPageChange}
					onRowsPerPageChange={onPageSizeChange}
					ActionsComponent={TablePaginationActions}
					sx={{ borderBottom: "none" }}
				/>
			</Box>
		</Box>
	);
};

export default DataTable;

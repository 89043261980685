import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

const VerifyIdentity = () => {
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [twoFactorToken, setTwoFactorToken] = useState("");
	const [tempToken, setTempToken] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get("token");
		if (token) {
			setTempToken(token);
			setMessage("Please enter the verification code sent to your email.");
		} else {
			setError("Invalid verification link");
		}
	}, [location.search]);

    const handleVerification = async (event) => {
        event.preventDefault();
        try {
          const response = await axios.post("/api/v1/auth/verify-2fa", { token: tempToken, twoFactorToken });
          localStorage.setItem("jwtToken", response.data.token); // Save the token in local storage
          localStorage.setItem("user", JSON.stringify(response.data.user)); // Optionally save user info
          navigate("/dashboard"); // Redirect to the dashboard on successful verification
        } catch (err) {
          setError("Verification failed. Please try again.");
        }
      };
      

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
					backgroundRepeat: "no-repeat",
					backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Verify Identity
					</Typography>
					<Box component="form" noValidate onSubmit={handleVerification} sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							name="twoFactorToken"
							label="Verification Code"
							type="text"
							id="twoFactorToken"
							value={twoFactorToken}
							onChange={(e) => setTwoFactorToken(e.target.value)}
							autoFocus
						/>
						{error && <Typography color="error">{error}</Typography>}
						{message && <Typography color="primary">{message}</Typography>}
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Verify
						</Button>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default VerifyIdentity;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const register = async (username, email, password) => {
    return axios.post(API_URL + '/auth/signup', {
      username,
      email,
      password,
    });
  };
  

const login = async (username, password) => {
    try {
      const response = await axios.post(API_URL + '/auth/signin', {
        username,
        password,
      });
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      // Throw the error so it can be caught in the component
      throw error.response.data;
    }
};
  
const verifyIdentity = async (token, twoFactorToken) => {
    const response = await axios.post(API_URL + '/auth/verify-identity', { token, twoFactorToken });
    if (response.data.token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  };
  
const logout = () => {
	localStorage.removeItem("user");
};

export default {
	register,
    login,
    verifyIdentity,
	logout,
};

import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    TextField,
    Button,
    Box,
    Grid,
    Typography,
    IconButton,
    InputAdornment,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    LinearProgress,
    MenuItem
} from '@mui/material';
import { Visibility, VisibilityOff, Password as PasswordIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import ClipboardJS from 'clipboard';
import zxcvbn from 'zxcvbn'; // Add zxcvbn for password strength
import api from '../../services/api'; // Ensure API import is included
import { useTheme } from '@mui/material/styles'; // Ensure useTheme is imported

const roles = ['admin', 'manager', 'staff'];

const generateSecurePassword = () => {
    const length = 16;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
};

const RegisterUserPage = () => {
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordStrengthText, setPasswordStrengthText] = useState('');
    const theme = useTheme();

    useEffect(() => {
        const clipboard = new ClipboardJS('.copy-button', {
            text: () => generatedPassword,
        });

        clipboard.on('success', () => {
            setSnackbarMessage('Password copied to clipboard!');
            setSnackbarOpen(true);
            clipboard.destroy();
        });

        clipboard.on('error', () => {
            setSnackbarMessage('Failed to copy password!');
            setSnackbarOpen(true);
            clipboard.destroy();
        });

        return () => {
            clipboard.destroy();
        };
    }, [generatedPassword]);

    const handleGeneratePassword = () => {
        const password = generateSecurePassword();
        setGeneratedPassword(password);
        setOpenPasswordDialog(true);
    };

    const handleCopyPassword = () => {
        const copyText = document.getElementById('generated-password-text');
        if (copyText) {
            copyText.select();
            document.execCommand('copy');
            setSnackbarMessage('Password copied to clipboard!');
            setSnackbarOpen(true);
        } else {
            setSnackbarMessage('Failed to copy password!');
            setSnackbarOpen(true);
        }
    };

    const handleUsePassword = (setFieldValue) => {
        setFieldValue('password', generatedPassword);
        setFieldValue('confirmPassword', generatedPassword);
        setOpenPasswordDialog(false);
    };

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const UserSchema = Yup.object().shape({
        displayName: Yup.string().required('Display Name is required'),
        username: Yup.string().required('Username is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        confirmEmail: Yup.string().oneOf([Yup.ref('email'), null], 'Emails must match').required('Confirm Email is required'),
        password: Yup.string().matches(/^\S*$/, 'No spaces allowed').required('Password is required'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
        role: Yup.string().required('Role is required')
    });

    const getPasswordStrengthText = (score) => {
        switch (score) {
            case 0:
                return 'Very Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return '';
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h5" gutterBottom>
                Register User
            </Typography>
            <Formik
                initialValues={{
                    displayName: '',
                    username: '',
                    email: '',
                    confirmEmail: '',
                    password: '',
                    confirmPassword: '',
                    role: '',
                }}
                validationSchema={UserSchema}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        const response = await api.post('/users/', values);
                        if (response.status === 201) { // Changed to 201 for "Created" status
                            setSnackbarMessage('User added successfully!');
                            setSnackbarOpen(true);
                            resetForm();
                            setPasswordStrength(null); // Reset password strength
                            setPasswordStrengthText(''); // Reset password strength text
                        }
                    } catch (error) {
                        setSnackbarMessage('Error adding user.');
                        setSnackbarOpen(true);
                        console.error('Error adding user:', error);
                    }
                }}
            >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field name="displayName">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            label="Display Name"
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field name="username">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            label="Username"
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field name="email">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field name="confirmEmail">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            label="Confirm Email"
                                            type="email"
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field name="password">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            label="Password"
                                            type={passwordVisible ? "text" : "password"}
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                const { score } = zxcvbn(e.target.value);
                                                setPasswordStrength(score);
                                                setPasswordStrengthText(getPasswordStrengthText(score));
                                            }}
                                        />
                                    )}
                                </Field>
                                {values.password && (
                                    <Box mt={1}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(passwordStrength + 1) * 25}
                                            sx={{ backgroundColor: theme.palette.error.dark }}
                                        />
                                        <Typography variant="body2" color="textSecondary">
                                            {passwordStrengthText}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field name="confirmPassword">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            label="Confirm Password"
                                            type={passwordVisible ? "text" : "password"}
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field name="role">
                                    {({ field, meta }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Role"
                                            fullWidth
                                            required
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error ? meta.error : ''}
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleGeneratePassword}
                                    startIcon={<PasswordIcon />}
                                    fullWidth
                                    style={{ height: "56px" }}
                                >
                                    Generate Password
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Register
                                </Button>
                            </Grid>
                        </Grid>
                        <Dialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
                            <DialogTitle>Generated Password</DialogTitle>
                            <DialogContent>
                                <TextField
                                    id="generated-password-text"
                                    variant="outlined"
                                    value={generatedPassword}
                                    fullWidth
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCopyPassword} color="primary" className="copy-button">
                                    Copy to Clipboard
                                </Button>
                                <Button onClick={() => handleUsePassword(setFieldValue)} color="primary">
                                    Use Password
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}>
                            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default RegisterUserPage;

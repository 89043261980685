import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PrintIcon from '@mui/icons-material/Print';
import ActivityIcon from '@mui/icons-material/Timeline';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UpdateIcon from '@mui/icons-material/Update';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  '&.active': {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ListItems = ({ undownloadedCount }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  
  return (
    <div>
      <StyledNavLink to="/dashboard">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </StyledNavLink>
      <StyledNavLink to="/dashboard/print-queue">
        <ListItem button>
          <ListItemIcon>
            <Badge badgeContent={undownloadedCount} color="error">
              <PrintIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Print Queue" />
        </ListItem>
      </StyledNavLink>
      <StyledNavLink to="/dashboard/activity">
        <ListItem button>
          <ListItemIcon>
            <ActivityIcon />
          </ListItemIcon>
          <ListItemText primary="Activity" />
        </ListItem>
      </StyledNavLink>
      {user && user.role === 'admin' && (
        <>
          <ListSubheader inset>User Management</ListSubheader>
          <StyledNavLink to="/dashboard/users">
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </StyledNavLink>
          <StyledNavLink to="/dashboard/add-user">
            <ListItem button>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Add User" />
            </ListItem>
          </StyledNavLink>
          <StyledNavLink to="/dashboard/update-user">
            <ListItem button>
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText primary="Update User" />
            </ListItem>
          </StyledNavLink>
        </>
      )}
    </div>
  );
};

export const mainListItems = (undownloadedCount) => <ListItems undownloadedCount={undownloadedCount} />;

import React, { useEffect, useState } from "react";
import { Grid, Box, Card, CardContent, Typography, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { Line, Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	LineElement,
	BarElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Title,
	Tooltip as ChartTooltip,
	Legend,
	Filler,
} from "chart.js";
import { useTheme } from "@mui/material/styles";
import api from "../../services/api";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ErrorIcon from "@mui/icons-material/Error";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TodayIcon from "@mui/icons-material/Today";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";

ChartJS.register(
	LineElement,
	BarElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Title,
	ChartTooltip,
	Legend,
	Filler
);

const DashboardPage = () => {
	const [data, setData] = useState([]);
	const [stats, setStats] = useState({});
	const [view, setView] = useState("daily");
	const [orderQuantityData, setOrderQuantityData] = useState([]);
	const theme = useTheme();

	useEffect(() => {
		fetchDashboardData();
	}, [view]);

	const fetchDashboardData = async () => {
		try {
			const response = await api.get(`/printQueue/dashboard-data/download-status?view=${view}`);
			setData(response.data.data);
			setStats(response.data.stats);

			// Fetch order quantity data
			const orderQuantityResponse = await api.get(`/printQueue/dashboard-data/order-quantity?view=${view}`);
            setOrderQuantityData(orderQuantityResponse.data.data);
            
             // Ensure totalSubmissions is set correctly
            setStats(prevStats => ({
                ...prevStats,
                totalSubmissions: orderQuantityResponse.data.totalSubmissions
            }));
		} catch (error) {
			console.error("Error fetching dashboard data:", error);
		}
	};

	const handleViewChange = (event, newView) => {
		if (newView !== null) {
			setView(newView);
		}
	};

	const createGradient = (ctx, area, color) => {
		if (!area) {
			return color;
		}
		const gradient = ctx.createLinearGradient(0, 0, 0, area.bottom);
		gradient.addColorStop(0, color);
		gradient.addColorStop(1, "rgba(0, 0, 0, 0)");
		return gradient;
	};

	const chartData = {
		labels: data.map((item) => item.datePart),
		datasets: [
			{
				label: "Total Entries",
				data: data.map((item) => item.count),
				borderColor: theme.palette.primary.main,
				backgroundColor: (context) =>
					createGradient(context.chart.ctx, context.chart.chartArea, theme.palette.primary.main),
				fill: true,
				tension: 0.4,
			},
			{
				label: "Downloaded",
				data: data.map((item) => item.downloadedCount),
				borderColor: theme.palette.secondary.main,
				backgroundColor: (context) =>
					createGradient(context.chart.ctx, context.chart.chartArea, theme.palette.secondary.main),
				fill: true,
				tension: 0.4,
			},
			{
				label: "Failed",
				data: data.map((item) => item.failedCount),
				borderColor: theme.palette.error.main,
				backgroundColor: (context) =>
					createGradient(context.chart.ctx, context.chart.chartArea, theme.palette.error.main),
				fill: true,
				tension: 0.4,
			},
		],
	};

	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
				labels: {
					color: theme.palette.text.primary,
				},
			},
			title: {
				display: true,
				text: "Print Queue Dashboard",
				color: theme.palette.text.primary,
			},
			tooltip: {
				backgroundColor: theme.palette.background.paper,
				titleColor: theme.palette.text.primary,
				bodyColor: theme.palette.text.primary,
			},
		},
		scales: {
			x: {
				ticks: {
					color: theme.palette.text.primary,
				},
				grid: {
					color: theme.palette.divider,
				},
			},
			y: {
				ticks: {
					color: theme.palette.text.primary,
				},
				grid: {
					color: theme.palette.divider,
				},
			},
		},
	};

	const createGradientForBar = (ctx, area, color) => {
		if (!area) {
			return color;
		}
		const gradient = ctx.createLinearGradient(0, 0, 0, area.bottom);
		gradient.addColorStop(0, color);
		gradient.addColorStop(1, "rgba(0, 0, 0, 0)");
		return gradient;
	};

	const orderQuantityChartData = {
		labels: orderQuantityData.map((item) => item.datePart),
		datasets: [
			{
				label: "Order Quantity",
				data: orderQuantityData.map((item) => item.orderQuantity),
				backgroundColor: (context) =>
					createGradientForBar(context.chart.ctx, context.chart.chartArea, theme.palette.primary.light),
				borderColor: theme.palette.primary.main,
				borderWidth: 2,
				hoverBackgroundColor: theme.palette.primary.dark,
			},
		],
	};

	const orderQuantityChartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
				labels: {
					color: theme.palette.text.primary,
				},
			},
			title: {
				display: true,
				text: "Order Quantity Over Time",
				color: theme.palette.text.primary,
			},
			tooltip: {
				backgroundColor: theme.palette.background.paper,
				titleColor: theme.palette.text.primary,
				bodyColor: theme.palette.text.primary,
			},
		},
		scales: {
			x: {
				ticks: {
					color: theme.palette.text.primary,
				},
				grid: {
					color: theme.palette.divider,
				},
			},
			y: {
				ticks: {
					color: theme.palette.text.primary,
				},
				grid: {
					color: theme.palette.divider,
				},
			},
		},
	};

	const styles = {
		icon: {
			fontSize: 40,
			color: theme.palette.primary.main,
			marginRight: theme.spacing(1),
		},
	};

	return (
		<div>
			<h1>Dashboard</h1>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={3}>
					<Card>
						<CardContent>
							<Grid container alignItems="center" spacing={2}>
								<Grid item>
									<AssignmentIcon style={styles.icon} />
								</Grid>
								<Grid item>
									<Typography variant="h5">{stats.totalEntries}</Typography>
									<Typography color="textSecondary">Total Entries</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Card>
						<CardContent>
							<Grid container alignItems="center" spacing={2}>
								<Grid item>
									<CloudDownloadIcon style={styles.icon} />
								</Grid>
								<Grid item>
									<Typography variant="h5">{stats.totalDownloaded}</Typography>
									<Typography color="textSecondary">Total Downloaded</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Card>
						<CardContent>
							<Grid container alignItems="center" spacing={2}>
								<Grid item>
									<ErrorIcon style={styles.icon} />
								</Grid>
								<Grid item>
									<Typography variant="h5">{stats.totalFailed}</Typography>
									<Typography color="textSecondary">Total Failed</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={3}>
					<Card>
						<CardContent>
							<Grid container alignItems="center" spacing={2}>
								<Grid item>
									<AssignmentIcon style={styles.icon} />
								</Grid>
								<Grid item>
									<Tooltip title={new Intl.NumberFormat("en-US").format(stats.totalSubmissions)}>
										<Typography variant="h5">
											{new Intl.NumberFormat("en-US", { notation: "compact" }).format(
												stats.totalSubmissions || 0
											)}
										</Typography>
									</Tooltip>
									<Typography color="textSecondary">Total Submissions</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<Box mt={3} display="flex" justifyContent="center">
				<ToggleButtonGroup value={view} exclusive onChange={handleViewChange} aria-label="view type">
					<ToggleButton value="daily" aria-label="daily">
						<TodayIcon sx={{ marginRight: 1 }} /> Daily
					</ToggleButton>
					<ToggleButton value="weekly" aria-label="weekly">
						<CalendarTodayIcon sx={{ marginRight: 1 }} /> Weekly
					</ToggleButton>
					<ToggleButton value="monthly" aria-label="monthly">
						<DateRangeIcon sx={{ marginRight: 1 }} /> Monthly
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Box mt={5}>
				<Line data={chartData} options={chartOptions} />
			</Box>
			<Box mt={5}>
				<Bar data={orderQuantityChartData} options={orderQuantityChartOptions} />
			</Box>
		</div>
	);
};

export default DashboardPage;

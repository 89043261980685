import React, { useEffect, useState, useRef, useCallback } from "react";
import api from "../../services/api";
import {
    TextField,
    MenuItem,
    Button,
    Grid,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    LinearProgress,
    Snackbar,
    Alert,
    Menu,
    Chip,
    Tooltip,
    IconButton,
    ClickAwayListener
} from "@mui/material";
import {
    Download as DownloadIcon,
    Flag as FlagIcon,
    Update as UpdateIcon,
    CopyAll as CopyIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import InputAdornment from "@mui/material/InputAdornment";

import DataTable from '../Common/DataTable';
import ClipboardJS from "clipboard";
import { useTheme } from "@mui/material/styles";
import debounce from 'lodash.debounce';


const PrintQueuePage = () => {

    const [printQueueEntries, setPrintQueueEntries] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [sortModel, setSortModel] = useState([{ field: "createdAt", sort: "desc" }]);
    const [filters, setFilters] = useState({
        downloadStatus: "",
        flag: "",
        fromDate: "",
        toDate: "",
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fileSize, setFileSize] = useState(0);
    const [statusMessage, setStatusMessage] = useState("Requesting file from Accredit");
    const abortControllerRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentFlagId, setCurrentFlagId] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);

    const prevPage = useRef(currentPage);
    const prevPageSize = useRef(pageSize);
    const prevSortModel = useRef(sortModel);
    const prevFilters = useRef(filters);
    const prevSearchQuery = useRef(searchQuery);


    const theme = useTheme();

    useEffect(() => {
        const totalPages = Math.ceil(totalItems / pageSize);
    
        if (currentPage >= totalPages) {
            setCurrentPage(0);
        } else {
            const pageChanged = prevPage.current !== currentPage;
            const pageSizeChanged = prevPageSize.current !== pageSize;
            const sortModelChanged = JSON.stringify(prevSortModel.current) !== JSON.stringify(sortModel);
            const filtersChanged = JSON.stringify(prevFilters.current) !== JSON.stringify(filters);
            const searchQueryChanged = prevSearchQuery.current !== searchQuery;
    
            if (pageChanged || pageSizeChanged || sortModelChanged || filtersChanged || searchQueryChanged) {
                console.log("Fetching data:", { currentPage, pageSize, sortModel, filters, searchQuery });
                setTableLoading(true);
                debouncedFetchPrintQueueEntries(currentPage + 1, pageSize, sortModel, filters, searchQuery);
            }
    
            prevPage.current = currentPage;
            prevPageSize.current = pageSize;
            prevSortModel.current = sortModel;
            prevFilters.current = filters;
            prevSearchQuery.current = searchQuery;
        }
    }, [currentPage, pageSize, sortModel, filters, searchQuery, totalItems]);
    
    useEffect(() => {
        debouncedFetchPrintQueueEntries(currentPage + 1, pageSize, sortModel, filters, searchQuery);
    }, [currentPage, pageSize, sortModel, filters, searchQuery]);
    
    
    const initialFilters = {
        downloadStatus: "",
        flag: "",
        fromDate: "",
        toDate: "",
    };

    const clearFilters = () => {
        setFilters(initialFilters);
        setSearchQuery("");
    };

    const debouncedFetchPrintQueueEntries = useCallback(
        debounce((page, pageSize, sortModel, filters, searchQuery) => {
            fetchPrintQueueEntries(page, pageSize, sortModel, filters, searchQuery);
        }, 500),
        []
    );

    const fetchPrintQueueEntries = async (page, pageSize, sortModel, filters, searchQuery) => {
        setTableLoading(true); // Set loading state to true before fetch
        try {
            const sortField = sortModel[0]?.field || "createdAt";
            const sortOrder = sortModel[0]?.sort || "desc";

            const response = await api.post("/printQueue", {
                page,
                pageSize,
                sortBy: sortField,
                sortOrder,
                filter: filters,
                searchQuery,
            });

            setPrintQueueEntries(response.data.items || []);
            setTotalItems(response.data.totalItems || 0);
        } catch (error) {
            console.error("Error fetching print queue entries:", error);
            setPrintQueueEntries([]);
            setTotalItems(0);
        } finally {
            setTableLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        const totalPages = Math.ceil(totalItems / pageSize);
        if (newPage < totalPages) {
            setCurrentPage(newPage);
        } else {
            setCurrentPage(0);
        }
    };
    

    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleTooltipOpen = (event, row) => {
        setTooltipOpen({ anchor: event.currentTarget, id: row.WebhookLog?.badgeExportZipFileId });
    };

    const handleTooltipClose = () => {
        setTooltipOpen(null);
    };

    const handleCopyToClipboard = (id) => {
        const clipboard = new ClipboardJS('.copy-button', {
            text: () => id
        });

        clipboard.on('success', () => {
            setCopySuccess(true);
            clipboard.destroy();
        });

        clipboard.on('error', () => {
            console.error('Failed to copy');
            clipboard.destroy();
        });
    };

    const fetchFileSize = async (fileExportId) => {
        try {
            const response = await api.get(`/printQueue/download-zip/${fileExportId}/metadata`);
            return response.data.FileSize;
        } catch (error) {
            console.error("Error fetching file size:", error);
            return 0;
        }
    };

    const handleDownload = async (fileExportId) => {
        setLoading(true);
        setDialogOpen(true);
        setProgress(0);
        setStatusMessage("Requesting file from Accredit");
    
        const size = await fetchFileSize(fileExportId);
        setFileSize(size);
    
        setStatusMessage("Downloading file...");
        abortControllerRef.current = new AbortController();
    
        let printQueueId = printQueueEntries.find(
            (entry) => entry.WebhookLog.badgeExportZipFileId === fileExportId
        ).id;
    
        try {
            const response = await api.get(`/printQueue/download-zip/${fileExportId}`, {
                responseType: "blob",
                signal: abortControllerRef.current.signal,
                onDownloadProgress: (progressEvent) => {
                    if (size > 0) {
                        setProgress(Math.round((progressEvent.loaded * 100) / size));
                    }
                },
            });
    
            if (response.status !== 200) {
                console.error("Error initiating download:", response.statusText);
                alert("Error initiating download. Please check the server logs for more details.");
                await handleUpdateDownloadStatus(printQueueId, 'FAILED');
                return;
            }
    
            // Extract the filename from the Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            let fileName = `file-${fileExportId}.zip`;
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }
    
            const blob = new Blob([response.data], { type: "application/zip" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            await handleUpdateDownloadStatus(printQueueId, 'DOWNLOADED');
        } catch (error) {
            if (error.name !== "CanceledError") {
                console.error("Error initiating download:", error.message, error.stack);
                alert(`Error initiating download: ${error.message}`);
                await handleUpdateDownloadStatus(printQueueId, 'FAILED');
            } else {
                console.log("Download canceled");
            }
        } finally {
            setLoading(false);
            setDialogOpen(false);
        }
    };
    

    const handleCancelDownload = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        setDialogOpen(false);
        setLoading(false);
    };

    const handleSetFlag = async (id, flag) => {
        try {
            const allowedFlags = [
                "FILE_CORRUPTED",
                "CSV_DATA_MISMATCH",
                "FILE_INCOMPLETE",
                "UNEXPECTED_FORMAT",
                "PREPRESS_ISSUE",
                "ART_ISSUE",
                "DUPLICATE",
                "ACCREDIT_ERROR",
                "FILE_MISSING"
            ];
    
            if (flag !== null && !allowedFlags.includes(flag)) {
                console.error("Invalid flag value");
                return;
            }
    
            await api.patch(`/printQueue/${id}/flag`, { flag });
    
            setPrintQueueEntries((prevEntries) =>
                prevEntries.map((entry) =>
                    entry.id === id ? { ...entry, flag } : entry
                )
            );
        } catch (error) {
            console.error("Error setting flag:", error);
        }
    };
    

    const handleUpdateDownloadStatus = async (id, status) => {
        try {
            const allowedStatus = ["PENDING", "DOWNLOADING", "DOWNLOADED", "FAILED"];
    
            if (!allowedStatus.includes(status)) {
                console.error("Invalid download status value");
                return;
            }
    
            const response = await api.patch(`/printQueue/${id}/download-status`, {
                downloadStatus: status,
            });
    
            const { downloadStatus, User: { username }, downloadedAt } = response.data;
    
            setPrintQueueEntries((prevEntries) =>
                prevEntries.map((entry) =>
                    entry.id === id ? { ...entry, downloadStatus, User: { ...entry.User, username }, downloadedAt } : entry
                )
            );
        } catch (error) {
            console.error("Error updating download status:", error);
        }
    };
    
    
    const getChipColor = (status) => {
        switch (status) {
          case "PENDING":
            return theme.palette.secondary.main;
          case "DOWNLOADING":
            return theme.palette.info.main;
          case "DOWNLOADED":
            return theme.palette.notice.main;
          case "FAILED":
            return theme.palette.error.main;
          case "FILE_CORRUPTED":
            return theme.palette.danger.main;
          case "CSV_DATA_MISMATCH":
            return theme.palette.warning.main;
          case "FILE_INCOMPLETE":
            return theme.palette.warning.main;
          case "UNEXPECTED_FORMAT":
            return theme.palette.warning.main;
          case "PREPRESS_ISSUE":
            return theme.palette.danger.main;
          case "ART_ISSUE":
            return theme.palette.danger.main;
          case "DUPLICATE":
            return theme.palette.secondary.main;
          case "ACCREDIT_ERROR":
            return theme.palette.error.main;
          case "FILE_MISSING":
            return theme.palette.error.main;
          default:
            return theme.palette.secondary.main;
        }
      };

    const handleFlagClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setCurrentFlagId(row.id);
    };

    const handleFlagClose = () => {
        setAnchorEl(null);
        setCurrentFlagId(null);
    };
    
    const handleFlagSelect = (flag) => {
        const selectedFlag = flag === "REMOVE_FLAG" ? null : flag;
        handleSetFlag(currentFlagId, selectedFlag);
        handleFlagClose();
    };

    const columns = [
        { 
            headerName: 'Docket', 
            field: 'WebhookLog.tenantExternalId', 
            flex: 1,
            customRenderCell: (row) => (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div onClick={(event) => handleTooltipOpen(event, row)}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={tooltipOpen?.id === row.WebhookLog?.badgeExportZipFileId}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <div>
                                    <Typography variant="caption">
                                        {row.WebhookLog?.badgeExportZipFileId}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        className="copy-button"
                                        onClick={() =>
                                            handleCopyToClipboard(
                                                row.WebhookLog?.badgeExportZipFileId
                                            )
                                        }>
                                        <CopyIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            }>
                            <span>{row.WebhookLog?.tenantExternalId}</span>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
            ),
        },
        {
            headerName: 'Zip File ID',
            field: 'WebhookLog.badgeExportZipFileId',
            flex: 1,
            customRenderCell: (row) => (
                <span>{row.WebhookLog?.badgeExportZipFileId}</span>
            ),
        },
        { 
            headerName: 'Team', 
            field: 'WebhookLog.tenantName', 
            flex: 1,
            customRenderCell: (row) => (
                <span>{row.WebhookLog?.tenantName}</span>
            ),
        },
        // { 
        //     headerName: 'Order Quantity', 
        //     field: 'orderQuantity', 
        //     flex: 1,
        //     customRenderCell: (row) => (
        //         <Typography style={{ fontWeight: "bold", color: theme.palette.primary.main }}>
        //             {new Intl.NumberFormat("en-US").format(row.orderQuantity)}
        //         </Typography>
        //     ),
        // },
        { 
            headerName: 'File Size', 
            field: 'fileSize', 
            flex: 1,
            customRenderCell: (row) => (
                <span>{row.fileSize ? `${(row.fileSize / (1024 * 1024)).toFixed(2)} MB` : "..."}</span>
            ),
        },
        { 
            headerName: 'Download Status',
            field: 'downloadStatus',
            flex: 1,
            customRenderCell: (row) => (
              <Chip
                label={row.downloadStatus || "No status"}
                variant="outlined"
                style={{
                  borderColor: getChipColor(row.downloadStatus),
                  color: getChipColor(row.downloadStatus),
                  backgroundColor: `${getChipColor(row.downloadStatus)}33`, // 20% opacity
                }}
              />
            ),
          },
          { 
            headerName: 'Flag',
            field: 'flag',
            flex: 1,
            customRenderCell: (row) => (
              <Chip
                label={row.flag || "No flag set"}
                variant="outlined"
                style={{
                  borderColor: getChipColor(row.flag),
                  color: getChipColor(row.flag),
                  backgroundColor: `${getChipColor(row.flag)}33`, // 20% opacity
                }}
              />
            ),
          },
        { 
            headerName: 'Last Downloaded By', 
            field: 'User.username', 
            flex: 1,
            customRenderCell: (row) => (
                <span>{row.User?.username || "..."}</span>
            ),
        },
        { 
            headerName: 'Downloaded At', 
            field: 'downloadedAt', 
            flex: 1,
            customRenderCell: (row) => (
                <span>{row.downloadedAt ? new Date(row.downloadedAt).toLocaleString() : "..."}</span>
            ),
        },
        { 
            headerName: 'Logged On', 
            field: 'createdAt', 
            flex: 1,
            customRenderCell: (row) => (
                <span>{row.createdAt ? new Date(row.createdAt).toLocaleString() : "..."}</span>
            ),
        },
    ];
    

    const actions = [
        {
          tooltip: 'Download',
          icon: (row) => (
            <IconButton
              size="small"
              onClick={() => handleDownload(row.WebhookLog?.badgeExportZipFileId)}
              style={{
                color: row.downloadStatus === 'DOWNLOADED' ? theme.palette.success.main :
                       row.downloadStatus === 'FAILED' ? theme.palette.error.main :
                       theme.palette.secondary.main,
              }}
            >
              <DownloadIcon />
            </IconButton>
          ),
        },
        // {
        //   tooltip: 'Update Download Status',
        //   icon: (row) => (
        //     <IconButton
        //       size="small"
        //       onClick={() => handleUpdateDownloadStatus(row.id, "DOWNLOADED")}
        //       style={{
        //         color: theme.palette.secondary.main,
        //       }}
        //     >
        //       <UpdateIcon />
        //     </IconButton>
        //   ),
        // },
        {
          tooltip: 'Set Flag',
          icon: (row) => (
            <IconButton
              size="small"
              onClick={(event) => handleFlagClick(event, row)}
              style={{
                color: row.flag ? getChipColor(row.flag) : theme.palette.secondary.main,
              }}
            >
              <FlagIcon />
            </IconButton>
          ),
        },
      ];
      
      
                  
      

    

    return (
		<div>
			<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Print Queue</Typography>
                <Button variant="outlined" color="secondary" onClick={clearFilters}>
                    Clear Filters
                </Button>
            </Box>
			<Box mb={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							select
							label="Download Status"
							name="downloadStatus"
							value={filters.downloadStatus}
							onChange={handleFilterChange}
							fullWidth>
							<MenuItem value="">All</MenuItem>
							<MenuItem value="PENDING">Pending</MenuItem>
							<MenuItem value="DOWNLOADING">Downloading</MenuItem>
							<MenuItem value="DOWNLOADED">Downloaded</MenuItem>
							<MenuItem value="FAILED">Failed</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							select
							label="Flag"
							name="flag"
							value={filters.flag}
							onChange={handleFilterChange}
							fullWidth>
							<MenuItem value="">All</MenuItem>
							<MenuItem value="FILE_CORRUPTED">File Corrupted</MenuItem>
							<MenuItem value="CSV_DATA_MISMATCH">CSV Data Mismatch</MenuItem>
							<MenuItem value="FILE_INCOMPLETE">File Incomplete</MenuItem>
							<MenuItem value="UNEXPECTED_FORMAT">Unexpected Format</MenuItem>
							<MenuItem value="PREPRESS_ISSUE">Prepress Issue</MenuItem>
							<MenuItem value="ART_ISSUE">Art Issue</MenuItem>
							<MenuItem value="DUPLICATE">Duplicate</MenuItem>
							<MenuItem value="ACCREDIT_ERROR">Accredit Error</MenuItem>
							<MenuItem value="FILE_MISSING">File Missing</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="From Date"
							name="fromDate"
							type="date"
							value={filters.fromDate}
							onChange={handleFilterChange}
							InputLabelProps={{ shrink: true }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label="To Date"
							name="toDate"
							type="date"
							value={filters.toDate}
							onChange={handleFilterChange}
							InputLabelProps={{ shrink: true }}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Search"
							name="searchQuery"
							value={searchQuery}
							onChange={handleSearchChange}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			
			</Box>
			<DataTable
				columns={columns}
				data={printQueueEntries}
				totalItems={totalItems}
				page={currentPage}
				pageSize={pageSize}
				onPageChange={handlePageChange}
				onPageSizeChange={handlePageSizeChange}
				actions={actions}
				loading={tableLoading}
			/>

			<Dialog open={dialogOpen}>
				<DialogTitle>{statusMessage}</DialogTitle>
				<DialogContent>
					<LinearProgress variant="determinate" value={progress} />
					<Typography>{progress}%</Typography>
					<Typography>Total File Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelDownload} color="primary" disabled={!loading}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar open={copySuccess} autoHideDuration={3000} onClose={() => setCopySuccess(false)}>
				<Alert onClose={() => setCopySuccess(false)} severity="success">
					Copied to clipboard!
				</Alert>
			</Snackbar>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleFlagClose}>
				<MenuItem onClick={() => handleFlagSelect("FILE_CORRUPTED")}>File Corrupted</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("CSV_DATA_MISMATCH")}>CSV Data Mismatch</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("FILE_INCOMPLETE")}>File Incomplete</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("UNEXPECTED_FORMAT")}>Unexpected Format</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("PREPRESS_ISSUE")}>Prepress Issue</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("ART_ISSUE")}>Art Issue</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("DUPLICATE")}>Duplicate</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("ACCREDIT_ERROR")}>Accredit Error</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("FILE_MISSING")}>File Missing</MenuItem>
				<MenuItem onClick={() => handleFlagSelect("REMOVE_FLAG")}>Remove Flag</MenuItem>
			</Menu>
		</div>
	);
};

export default PrintQueuePage;

import axios from 'axios';

// Create an Axios instance
const api = axios.create({
    baseURL: '/api/v1', // Adjust based on your API base URL
    headers: {
        'Content-Type': 'application/json', // Ensure Content-Type is set
      },
});

// Add a request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    // Token expired or not valid
    localStorage.removeItem('jwtToken'); // Remove the expired token
      // removed expired user credentials
    localStorage.removeItem('user');
    window.location.href = '/login'; // Redirect to login page
  }
  return Promise.reject(error);
});

export default api;

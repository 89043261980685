import React, { useEffect, useState } from "react";
import {
	Timeline,
	TimelineItem,
	TimelineSeparator,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineOppositeContent,
} from "@mui/lab";
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import api from "../../services/api";

const getChanges = (previous, current) => {
	const changes = {};
	for (const key in previous) {
		let prevValue = previous[key] !== null ? previous[key] : "empty";
		let currValue = current[key] !== null ? current[key] : "empty";

		// Format dates if they are valid date strings
		if (moment(prevValue, moment.ISO_8601, true).isValid()) {
			prevValue = moment(prevValue).format("MMMM Do YYYY, h:mm:ss a");
		}
		if (moment(currValue, moment.ISO_8601, true).isValid()) {
			currValue = moment(currValue).format("MMMM Do YYYY, h:mm:ss a");
		}

		if (prevValue !== currValue) {
			changes[key] = { previous: prevValue, current: currValue };
		}
	}
	return changes;
};

const renderActivityDetails = (log) => {
	const previousValues = JSON.parse(log.previousValues);
	const newValues = JSON.parse(log.newValues);
	const changes = getChanges(previousValues, newValues);

	console.log("Changes:", changes); // Log the changes to verify the structure

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>Changes</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{Object.keys(changes).map((key) => (
					<Typography key={key} variant="body2" color="textSecondary">
						<strong>{key}:</strong>
						{typeof changes[key].previous === "object"
							? JSON.stringify(changes[key].previous)
							: changes[key].previous}{" "}
						->
						{typeof changes[key].current === "object"
							? JSON.stringify(changes[key].current)
							: changes[key].current}
					</Typography>
				))}
			</AccordionDetails>
		</Accordion>
	);
};

const ActivityPage = () => {
	const [activityLogs, setActivityLogs] = useState([]);
	const [visibleLogs, setVisibleLogs] = useState([]);
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		const fetchActivityLogs = async () => {
			setLoading(true);
			try {
				const response = await api.get("/activityLogs", {
					params: { limit: 10, offset },
				});
				console.log("Activity Logs:", response.data); // Log the data received

				setActivityLogs((prevLogs) => [...prevLogs, ...response.data]);
				setVisibleLogs((prevLogs) => [...prevLogs, ...response.data]);

				if (response.data.length < 10) {
					setHasMore(false);
				}
			} catch (error) {
				console.error("Error fetching activity logs:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchActivityLogs();
	}, [offset]);

	const loadMore = () => {
		setOffset((prevOffset) => prevOffset + 10);
	};

	return (
		<div>
			<h1>Activity</h1>
			<Timeline position="alternate">
				{visibleLogs.map((log) => (
					<TimelineItem key={log.id}>
						<TimelineOppositeContent>
							<Typography variant="body2" color="textSecondary">
								{moment(log.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
							</Typography>
						</TimelineOppositeContent>
						<TimelineSeparator>
							<TimelineDot
								color={
									log.action === "UPDATE" ? "primary" : log.action === "CREATE" ? "success" : "error"
								}
							/>
							<TimelineConnector />
						</TimelineSeparator>
						<TimelineContent>
							<Box mb={2}>
								<Typography variant="h6" component="span">
									{log.action} - {log.entityType}
								</Typography>
								<Typography variant="body2" color="textSecondary" gutterBottom>
									<strong>Performed By:</strong> {log.User?.username || log.updatedBy}
								</Typography>
								<Box mb={1} />
								{renderActivityDetails(log)}
							</Box>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
			{hasMore && (
				<Box display="flex" justifyContent="center" mt={2}>
					<Button variant="contained" color="primary" onClick={loadMore} disabled={loading}>
						{loading ? "Loading..." : "Load More"}
					</Button>
				</Box>
			)}
		</div>
	);
};

export default ActivityPage;

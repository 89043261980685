import React, { useEffect, useState, useRef, useCallback } from 'react';
import api from '../../services/api';
import {
    TextField,
    MenuItem,
    Button,
    Grid,
    Box,
    Typography,
    IconButton,
    InputAdornment
} from '@mui/material';
import { Edit, Delete, Search as SearchIcon } from '@mui/icons-material';
import DataTable from '../Common/DataTable';
import debounce from 'lodash.debounce';

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalItems, setTotalItems] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);

    const prevPage = useRef(currentPage);
    const prevPageSize = useRef(pageSize);
    const prevSearchTerm = useRef(searchTerm);

    useEffect(() => {
        // Initial fetch on mount
        setTableLoading(true);
        debouncedFetchUsers(currentPage + 1, pageSize, searchTerm);
    }, []);

    useEffect(() => {
        if (
            prevPage.current !== currentPage ||
            prevPageSize.current !== pageSize ||
            prevSearchTerm.current !== searchTerm
        ) {
            setTableLoading(true);
            debouncedFetchUsers(currentPage + 1, pageSize, searchTerm);
        }
        prevPage.current = currentPage;
        prevPageSize.current = pageSize;
        prevSearchTerm.current = searchTerm;
    }, [currentPage, pageSize, searchTerm]);

    const debouncedFetchUsers = useCallback(
        debounce((page, pageSize, searchTerm) => {
            fetchUsers(page, pageSize, searchTerm);
        }, 500),
        []
    );

    const fetchUsers = async (page, pageSize, searchTerm) => {
        setTableLoading(true);
        try {
            const response = await api.get('/users', {
                params: {
                    page,
                    pageSize,
                    searchTerm,
                },
            });
            setUsers(response.data.items);
            setTotalItems(response.data.totalItems);
        } catch (error) {
            console.error('Error fetching users:', error);
            setUsers([]);
            setTotalItems(0);
        } finally {
            setTableLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEdit = (id) => {
        // Your edit logic here
    };

    const handleDelete = (id) => {
        // Your delete logic here
    };

    const columns = [
        { headerName: 'Username', field: 'username', flex: 1 },
        { headerName: 'Email', field: 'email', flex: 1 },
        { headerName: 'Role', field: 'role', flex: 1 },
    ];

    const actions = [
        {
            tooltip: 'Edit',
            icon: (row) => (
                <IconButton color="primary" onClick={() => handleEdit(row.id)}>
                    <Edit />
                </IconButton>
            ),
        },
        {
            tooltip: 'Delete',
            icon: (row) => (
                <IconButton color="secondary" onClick={() => handleDelete(row.id)}>
                    <Delete />
                </IconButton>
            ),
        },
    ];

    const clearFilters = () => {
        setSearchTerm("");
    };

    return (
        <Box p={3}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Users</Typography>
                <Button variant="outlined" color="secondary" onClick={clearFilters}>
                    Clear Filters
                </Button>
            </Box>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Search Users"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <DataTable
                columns={columns}
                data={users}
                totalItems={totalItems}
                page={currentPage}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                actions={actions}
                loading={tableLoading}
            />
        </Box>
    );
};

export default UsersPage;

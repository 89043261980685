import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Auth/Login";
import VerifyIdentity from "./Auth/VerifyIdentity";
import Dashboard from "./Dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import DashboardPage from "./Pages/DashboardPage";
import PrintQueuePage from "./Pages/PrintQueuePage";
import ActivityPage from "./Pages/ActivityPage";
import UsersPage from "./Pages/UsersPage";
import RegisterUserPage from "./Pages/RegisterUserPage";
import UpdateUserPage from "./Pages/UpdateUserPage";
import darkTheme from "../theme/theme"; // Adjust the import path as needed
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ErrorBoundary from "./Common/ErrorBoundary";

const App = () => {
	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<ErrorBoundary>
				<Router>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/verify-identity" element={<VerifyIdentity />} />
						<Route
							path="/dashboard"
							element={
								<ProtectedRoute>
									<Dashboard />
								</ProtectedRoute>
							}>
							<Route
								index
								element={
									<ProtectedRoute>
										<DashboardPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="print-queue"
								element={
									<ProtectedRoute>
										<PrintQueuePage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="activity"
								element={
									<ProtectedRoute>
										<ActivityPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="users"
								element={
									<ProtectedRoute requiredRole="admin">
										<UsersPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="add-user"
								element={
									<ProtectedRoute requiredRole="admin">
										<RegisterUserPage />
									</ProtectedRoute>
								}
							/>
							<Route
								path="update-user"
								element={
									<ProtectedRoute requiredRole="admin">
										<UpdateUserPage />
									</ProtectedRoute>
								}
							/>
						</Route>
						<Route path="/" element={<Login />} />
					</Routes>
				</Router>
			</ErrorBoundary>
		</ThemeProvider>
	);
};

export default App;

import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";

import backgroundImage from "./assets/images/403.png";

export default function SignInSide() {
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const user = localStorage.getItem("user");
		if (user) {
			navigate("/dashboard");
		}
	}, [navigate]);

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const username = data.get("username");
		const password = data.get("password");

		try {
			const response = await authService.login(username, password);
			if (response.message === "Verification link sent to email") {
				setMessage("A verification link has been sent to your email. Please check your inbox.");
			} else {
				navigate("/dashboard"); // Redirect to the dashboard on successful login
			}
		} catch (err) {
			setError(err.error || "Login failed. Please check your credentials and try again.");
		}
	};

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<CssBaseline />
			<Grid
				item
				xs={12}
				sx={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundRepeat: "no-repeat",
					backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
					backgroundSize: "contain",
					backgroundPosition: "left",
					position: "relative",
				}}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						right: 0,
						transform: "translateY(-50%)",
						bgcolor: "background.paper",
						borderTopLeftRadius: 8,
						borderBottomLeftRadius: 8,
						p: 1,
					}}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="end"
						onClick={handleDrawerOpen}
						sx={{ color: "text.primary" }}>
						<PersonIcon />
					</IconButton>
				</Box>
				<Card
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						bgcolor: "background.paper",
						p: 2,
						minWidth: 300,
					}}>
					<CardContent>
						<Typography variant="h2" component="div" align="center">
							403 Forbidden
						</Typography>
						<Typography variant="h6" align="center">
							Oops! Looks like you need an access upgrade.<br></br>We know a great place for that---hint:
							it's us... <br></br>
							<Link variant="h6" href="https://cubepasses.com">
								www.CubePasses.com
							</Link>
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
				<Box sx={{ width: 600, bgcolor: "background.paper", height: "100%" }}>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="username"
								label="Username"
								name="username"
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
							/>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/>
							{error && <Typography color="error">{error}</Typography>}
							{message && <Typography color="primary">{message}</Typography>}
							<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
								Sign In
							</Button>
							<Grid container>
								<Grid item xs></Grid>
								<Grid item></Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</Drawer>
		</Grid>
	);
}

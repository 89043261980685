import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9bd700',
      light: '#b7e84a',
      dark: '#7ba600',
    },
    secondary: {
      main: '#7f7f7f',
      light: '#4f4f4f',
      dark: '#2c2c2c',
    },
    background: {
      default: '#121212',
      paper: '#1c1c1c',
    },
    text: {
      primary: '#ffffff',
      secondary: '#9e9e9e',
    },
    error: {
      main: '#ff6b6b',
      dark: '#b71c1c',
    },
    info: {
      main: '#2196f3',
      dark: '#1976d2',
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
    },
    notice: {
      main: '#4caf50',
      dark: '#388e3c',
    },
    danger: {
      main: '#f44336',
      dark: '#d32f2f',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  spacing: 8, // Default spacing is 8px
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1c',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2c2c2c',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1c',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderColor: 'currentColor',
          color: 'currentColor',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  },
});

export default darkTheme;

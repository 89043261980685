import React from 'react';
import { Navigate } from 'react-router-dom';

// Function to check token expiration
const isTokenExpired = (token) => {
  if (!token) return true;

  const { exp } = JSON.parse(atob(token.split('.')[1]));
  return Date.now() >= exp * 1000;
};

const ProtectedRoute = ({ children, requiredRole }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const jwtToken = localStorage.getItem('jwtToken');

  if (!user || isTokenExpired(jwtToken)) {
    // Remove user and jwtToken from localStorage if expired or not authenticated
    localStorage.removeItem('user');
    localStorage.removeItem('jwtToken');
    return <Navigate to="/login" />;
  }

  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default ProtectedRoute;
